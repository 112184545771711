<template>
  <div>
    <main v-if="is_API_loaded" class="router-view-container space-y-5">
      <div class="flex items-center space-x-4">
        <!-- 返回按鈕 -->
        <vs-button @click="$router.go(-1)" border size="large" class="flex-shrink-0">
          <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
        </vs-button>
        <h1 class="page_title">{{ $route.meta.title }}</h1>
      </div>

      <!-- 訂單編號和操作 -->
      <section class="w-full flex justify-between flex-wrap">
        <!-- 訂單編號 -->
        <div>
          <h1 class="h1">{{ order.ordernum }}</h1>
          <p>{{ order.odate }}</p>
        </div>

        <!-- 訂單操作 -->
        <section class="flex items-center">
          <vs-button transparent size="large" @click.stop="dropdownClick(99)" style="overflow: visible; z-index: 10" class="relative">
            <div class="order_icon_btn">
              <svg-icon icon-class="setting_2" class="sm"></svg-icon>
              <div class="flex items-center space-x-1">
                <span>訂單操作</span>
                <svg-icon icon-class="triangle" className="primary" style="width: 0.7rem; height: 0.7rem"></svg-icon>
              </div>
            </div>
            <transition name="fade">
              <dropdown v-if="dropdownIndex === 99">
                <div class="space-y-1">
                  <vs-button transparent size="large" @click="payed_Dialog = true" v-if="bstatus.payedStatus">變更已付款</vs-button>
                  <vs-button transparent size="large" @click="pickup_Dialog = true" v-if="bstatus.pickupStatus">變更已取貨</vs-button>
                  <vs-button transparent size="large" @click="getReturnMOrder" v-if="bstatus.returnStatus">訂單退貨</vs-button>
                  <vs-button transparent size="large" @click="getRefundMOrder" v-if="bstatus.refundStatus">訂單退款</vs-button>
                  <vs-button transparent size="large" color="danger" @click="getCancelMOrder" v-if="bstatus.cancelStatus"
                    >取消訂單</vs-button
                  >
                  <p
                    v-if="
                      !bstatus.payedStatus &&
                      !bstatus.pickupStatus &&
                      !bstatus.returnStatus &&
                      !bstatus.refundStatus &&
                      !bstatus.cancelStatus
                    "
                    class="text-xs text-gray-400"
                  >
                    已無可用操作
                  </p>
                  <!-- <vs-button transparent size="large" color="danger" @click="dMOrder">刪除訂單</vs-button> -->
                </div>
              </dropdown>
            </transition>
          </vs-button>
          <vs-button transparent size="large" @click="getShippingData" v-if="bstatus.shippingStatus">
            <div class="order_icon_btn">
              <svg-icon icon-class="order_box" class="sm gray-text-light_stroke"></svg-icon>
              <span>出貨</span>
            </div>
          </vs-button>
          <vs-button @click="toPackingSlip" transparent size="large">
            <div class="order_icon_btn">
              <svg-icon icon-class="print" class="sm"></svg-icon>
              <span>列印揀貨單</span>
            </div>
          </vs-button>
        </section>
      </section>

      <!-- 2. 訂單資料 -->
      <section class="flex flex-wrap md:flex-nowrap space-y-4 md:space-y-0 md:space-x-4">
        <!-- 2.1.1 -->
        <div class="w-full md:w-14/20 space-y-4 flex-shrink-0">
          <article class="box" style="padding-left: 0; padding-right: 0">
            <section class="px-4">
              <div class="flex justify-start space-x-4">
                <!-- 訂單狀態 -->
                <span
                  class="rounded-full px-3 py-1"
                  :class="{
                    'bg-success text-white': order.ostatus === 0,
                    'bg-danger text-white': order.ostatus === 1,
                    'bg-gray-background text-gray-text_light': order.ostatus === 2 || order.ostatus === 3,
                  }"
                >
                  {{ ostatusMixin(order.ostatus) }}
                </span>
                <!-- 付款狀態 -->
                <span
                  class="rounded-full px-3 py-1"
                  :class="{
                    'bg-warning text-white': order.pstatus === -1,
                    'bg-danger text-white': order.pstatus === 0,
                    'bg-success  text-white': order.pstatus === 1 || order.pstatus === 2,
                    'bg-gray-background text-gray-text_light': order.pstatus === 3,
                  }"
                >
                  {{ pstatusMixin(order.pstatus) }}
                </span>
                <!-- 出貨狀態 -->
                <span
                  class="rounded-full px-3 py-1"
                  :class="{
                    'bg-danger text-white': order.lstatus === 0,
                    'bg-success text-white': order.lstatus === 2 || order.lstatus === 4,
                    'bg-gray-background text-gray-text_light': order.lstatus === 3 || order.lstatus === 6,
                    'bg-warning text-white': order.lstatus === 1 || order.lstatus === 5,
                  }"
                >
                  {{ lstatusMixin(order.lstatus) }}
                </span>
              </div>
            </section>
            <!-- 訂單項目區塊 -->
            <section class="overflow-x-auto">
              <div class="min-w-full w-max">
                <!-- 訂單商品標題 -->
                <div class="flex items-center px-4 py-2 text-gray-text_dark">
                  <p class="product_list_1">商品</p>
                  <p class="product_list_2">數量</p>
                  <p class="product_list_3">總計</p>
                </div>
              </div>
              <!-- 商品列表 -->
              <div class="space-y-4 py-4 min-w-full w-max border-t-2 border-b-2 border-gray-background">
                <div v-for="(item, index) in order.orderspec_arr" :key="index" class="flex items-center px-4">
                  <div class="product_list_1 flex items-center">
                    <!-- 商品圖 -->
                    <div class="w-12 h-12 rounded-xl overflow-hidden shadow-md flex-shrink-0">
                      <img v-if="item.img" class="object-cover h-full w-full" :src="item.img" />
                      <svg-icon v-else icon-class="product_tag" className="gray-border sm"></svg-icon>
                    </div>
                    <!-- 標題 / 規格 / 單價 -->
                    <div class="text-left flex-grow px-4" style="max-width: 230px">
                      <a :href="item.wlink" target="_blank" rel="noopener noreferrer" class="text-success hover:underline">{{
                        item.pname
                      }}</a>
                      <div class="text-xs">
                        <span>{{ item.spec1 }}</span>
                        <span v-if="item.spec2">{{ ' / ' + item.spec2 }}</span>
                        <span v-if="item.spec3">{{ ' / ' + item.spec3 }}</span>
                      </div>
                      <p class="text-xs">NT${{ item.sprice | currency }}</p>
                    </div>
                  </div>
                  <!-- 數量 -->
                  <div class="product_list_2">
                    <span>{{ item.amount }}</span>
                    <span
                      class="rounded-full ml-2 px-2 py-1 text-white"
                      :class="{
                        'bg-warning': item.samount1 < item.amount && item.samount1 > 0,
                        'bg-danger': item.samount1 === 0,
                        'bg-success': item.samount1 === item.amount,
                      }"
                      >{{ item.samount1 }} 已出貨</span
                    >
                  </div>
                  <!-- 總計 -->
                  <div class="product_list_3">
                    <span>NT${{ item.opays | currency }}</span>
                  </div>
                </div>
              </div>
            </section>
            <!-- 小計 / 運費 / 總計 區塊 -->
            <section class="px-4 space-y-4">
              <div class="flex">
                <p class="flex-grow text-right">小計</p>
                <p class="w-36 text-right">NT${{ order.ppays | currency }}</p>
              </div>
              <div class="flex">
                <p class="flex-grow text-right">運費</p>
                <p class="w-36 text-right">NT${{ order.lpays | currency }}</p>
              </div>
              <div class="flex" v-if="order.dname1 != ''">
                <p class="flex-grow text-right">{{ order.dname1 }}</p>
                <p class="w-36 text-right">- NT${{ Math.abs(order.discount1) | currency }}</p>
              </div>
              <div class="flex" v-if="order.dname2 != ''">
                <p class="flex-grow text-right">{{ order.dname2 }}</p>
                <p class="w-36 text-right">- NT${{ Math.abs(order.discount2) | currency }}</p>
              </div>
              <div class="flex" v-if="order.dname3 != ''">
                <p class="flex-grow text-right">{{ order.dname3 }}</p>
                <p class="w-36 text-right">- NT${{ Math.abs(order.discount3) | currency }}</p>
              </div>
              <div class="flex" v-if="order.dname4 != ''">
                <p class="flex-grow text-right">{{ order.dname4 }}</p>
                <p class="w-36 text-right">- NT${{ Math.abs(order.discount4) | currency }}</p>
              </div>
              <div class="flex" v-if="order.dname5 != ''">
                <p class="flex-grow text-right">{{ order.dname5 }}</p>
                <p class="w-36 text-right">- NT${{ Math.abs(order.discount5) | currency }}</p>
              </div>
              <!-- 購物金 -->
              <div class="flex" v-if="order.point1">
                <p class="flex-grow text-right">折抵購物金</p>
                <p class="w-36 text-right">- NT${{ Math.abs(order.point1) | currency }}</p>
              </div>
              <div class="flex text-gray-text_dark font-bold">
                <p class="flex-grow text-right">總計</p>
                <p class="w-36 text-right">NT${{ order.opays | currency }}</p>
              </div>
            </section>
            <hr class="border-t-2 border-gray-background" />
            <!-- 訂單備註 -->
            <section class="px-4 space-y-4 w-full">
              <div class="flex items-center justify-between">
                <h3 class="h3">訂單備註</h3>
                <vs-button size="mini" transparent color="success" @click="getONote">
                  <span class="font-medium text-success text-sm">編輯</span>
                </vs-button>
              </div>
              <p v-if="order.onote" class="whitespace-pre-line">{{ order.onote }}</p>
              <p v-else class="text-gray-divide">尚無任何訂單備註</p>
            </section>
          </article>
          <article class="box">
            <h3 class="h3">訂單歷程</h3>
            <accordion class="divide-y-2 divide-gray-background">
              <accordion-item v-for="(item, index) in order.orderlog_arr" :key="index">
                <template slot="accordion-trigger">
                  <p class="font-bold">{{ item.note }} - {{ item.cdate }}</p>
                </template>
                <template slot="accordion-content">
                  <div v-if="item.isenable === 1" class="flex justify-start my-2">
                    <vs-button
                      size="mini"
                      flat
                      color="primary"
                      @click="getShippingStatusData(item.smolno, index)"
                      :ref="`getShippingStatusDataBtn${index}`"
                    >
                      編輯
                    </vs-button>
                  </div>
                  <div v-for="(item2, index2) in item.item_arr" :key="index2">
                    <p>{{ item2.key }}: {{ item2.value }}</p>
                  </div>
                </template>
              </accordion-item>
            </accordion>
          </article>
        </div>

        <!-- 2.2 -->
        <div class="flex-grow space-y-4">
          <div class="box">
            <div class="flex items-center justify-between">
              <h3 class="h3">購買人資料</h3>
              <vs-button @click="getBData" size="mini" transparent color="success">
                <span class="font-medium text-success text-sm">編輯</span>
              </vs-button>
            </div>
            <div>
              <router-link
                v-if="order.smno"
                class="text-success hover:underline"
                :to="{ name: 'edit-customer', params: { id: order.smno } }"
                >{{ order.uname }}</router-link
              >
              <p v-else class="break_word">{{ order.uname }}</p>
              <p class="break_word">{{ order.mp }}</p>
              <p class="break_word">{{ order.email }}</p>
            </div>
          </div>
          <div class="box">
            <div class="flex items-center justify-between">
              <h3 class="h3">收件人資料</h3>
              <vs-button
                @click="getPtermsLogisticsList(0)"
                size="mini"
                transparent
                color="success"
                v-if="bstatus.rdataStatus"
                ref="getPtermsLogisticsListBtn0"
              >
                <span class="font-medium text-success text-sm">編輯</span>
              </vs-button>
            </div>
            <div>
              <!-- <p class="break_word">{{ lthirdMixin(order.lthird) }} {{ logisticsMixin(order.logistics) }}</p> -->
              <p>{{ order.logistics_val }}</p>
              <!-- 宅配才顯示溫層 -->
              <p v-if="order.logistics >= 5">溫層: {{ tempMixin(order.temp) }}</p>
              <!-- 規格:{{ specMixin(order.spec) }} -->
              <!-- 送貨時間:{{ ltimeMixin(order.ltime) }} -->
              <p class="break_word mt-2">{{ order.runame }}</p>
              <p class="break_word">{{ order.rmp }}</p>
              <p class="break_word">{{ order.remail }}</p>
              <div v-if="order.logistics >= 5">
                <p class="break_word">
                  {{ order.rshit }}
                </p>
                <p class="break_word">{{ order.rcity }} {{ order.rarea }} {{ order.rzipcode }}</p>
                <p class="break_word">
                  {{ order.raddress1 }}
                </p>
                <p class="break_word">
                  {{ order.raddress2 }}
                </p>
              </div>
              <p class="break_word" v-if="order.logistics >= 1 && order.logistics <= 4">{{ order.store_name }} {{ order.store_id }}</p>
            </div>
          </div>
          <div class="box">
            <div class="flex items-center justify-between">
              <h3 class="h3">付款方式</h3>
              <vs-button
                @click="getPtermsLogisticsList(1)"
                ref="getPtermsLogisticsListBtn1"
                size="mini"
                transparent
                color="success"
                v-if="bstatus.ptermsStatus"
              >
                <span class="font-medium text-success text-sm">編輯</span>
              </vs-button>
            </div>
            <div>
              <p class="break_word">{{ pthirdMixin(order.pthird) }} {{ ptermsMixin(order.pterms) }}</p>
            </div>
          </div>
          <div class="box" v-if="order.rdate != ''">
            <div class="flex items-center justify-between">
              <h3 class="h3">退款資訊</h3>
            </div>
            <div>
              <p class="break_word">退款金額: NT${{ Math.abs(order.rrefund) | currency }}</p>
              <p class="break_word" v-if="order.repoint1 != 0">退購物金: NT${{ Math.abs(order.repoint1) | currency }}</p>
              <p class="break_word">退款備註: {{ order.rnote }}</p>
              <p class="break_word">退款時間: {{ order.rdate }}</p>
            </div>
          </div>
          <div class="box">
            <div class="flex items-center justify-between">
              <h3 class="h3">發票資訊</h3>
              <vs-button @click="getInvoice" size="mini" transparent color="success">
                <span class="font-medium text-success text-sm">編輯</span>
              </vs-button>
            </div>
            <div>
              <p class="break_word">{{ intype1Mixin(order.intype1) }} {{ intype2Mixin(order.intype2) }}</p>
              <p class="break_word">{{ order.cname }} {{ order.cvat }}</p>
              <p class="break_word">{{ order.invoice }}</p>
            </div>
          </div>
          <div class="box">
            <div class="flex items-center justify-between">
              <h3 class="h3">商家備註</h3>
              <vs-button size="mini" transparent color="success" @click="getSNote">
                <span class="font-medium text-success text-sm">編輯</span>
              </vs-button>
            </div>
            <p v-if="order.snote" class="whitespace-pre-line" v-html="order.snote"></p>
            <p v-else class="text-gray-divide">尚無任何商家備註</p>
          </div>
          <div class="box" v-if="order.cancel_date != ''">
            <div class="flex items-center justify-between">
              <h3 class="h3">取消備註</h3>
            </div>
            <p class="whitespace-pre-line" v-html="order.cancel_note"></p>
            <p class="break_word">{{ order.cancel_date }}</p>
          </div>
        </div>
      </section>
    </main>

    <!-- 骨架屏 -->
    <div v-if="!is_API_loaded" class="router-view-container space-y-4">
      <section class="flex items-center space-x-4 justify-between">
        <skeleton width="48px" />

        <div class="flex lg:w-full items-center justify-between">
          <skeleton class="hidden lg:block" width="50%" height="40px" />
          <div class="flex items-center space-x-2">
            <skeleton width="82px" />
            <skeleton width="82px" />
          </div>
        </div>
      </section>
      <skeleton class="lg:hidden" width="80%" height="40px" />

      <section class="flex flex-wrap md:flex-nowrap space-y-4 md:space-y-0 md:space-x-4">
        <div class="w-full md:w-14/20 space-y-4">
          <article class="box">
            <skeleton />

            <div>
              <skeleton height="200px" />
            </div>
          </article>

          <article class="box">
            <skeleton />
          </article>
        </div>

        <div class="flex-grow space-y-4">
          <div class="box">
            <skeleton />
            <skeleton />
            <skeleton />
          </div>
          <div class="box">
            <skeleton />
            <skeleton />
            <skeleton />
          </div>
        </div>
      </section>

      <hr />

      <section class="flex items-center justify-between">
        <skeleton width="82px" />
        <skeleton width="82px" />
      </section>
    </div>

    <!-- 編輯出貨 dialog -->
    <ValidationObserver v-slot="{ invalid }">
      <vs-dialog overflow-hidden blur v-model="shipping_Dialog" not-center>
        <template #header>
          <h3 class="h3">出貨</h3>
        </template>
        <section class="text-sm text-gray-text_dark mb-3">
          <div class="w-full">
            <!-- 訂單商品標題 -->
            <div class="flex items-center py-2 text-gray-text_dark">
              <p class="product_list_1 in_shipping_content">商品</p>
              <p class="product_list_2 in_shipping_content">數量</p>
            </div>
          </div>
          <!-- 商品列表 -->
          <div class="space-y-4 py-4 w-full border-t-2 border-b-2 border-gray-background">
            <div v-for="(item, index) in orderShippinglog" :key="index" class="flex items-center">
              <div class="product_list_1 in_shipping_content flex items-center">
                <!-- 商品圖 -->
                <div class="w-12 h-12 rounded-xl overflow-hidden shadow-md flex-shrink-0">
                  <img v-if="item.img" class="object-cover h-full w-full" :src="item.img" />
                  <svg-icon v-else icon-class="product_tag" className="gray-border sm"></svg-icon>
                </div>
                <!-- 標題 / 規格 / 單價 -->
                <div class="text-left flex-grow px-4">
                  <p class="text-success hover:underline">{{ item.pname }}</p>
                  <div class="text-xs">
                    <span>{{ item.spec1 }}</span>
                    <span v-if="item.spec2">{{ ' / ' + item.spec2 }}</span>
                    <span v-if="item.spec3">{{ ' / ' + item.spec3 }}</span>
                  </div>
                </div>
              </div>
              <!-- 數量 -->
              <ValidationProvider
                tag="div"
                class="relative flex-shrink-0 product_list_2 in_shipping_content"
                name="數量"
                :rules="`required|numeric|min_value:0|max_value:${item.max_samount2}`"
                v-slot="{ errors }"
              >
                <div class="flex items-center justify-between">
                  <vs-input placeholder="必填" v-model="item.samount2" style="width: 70px" />
                  <span>of {{ item.max_samount2 }}</span>
                </div>
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
          </div>

          <!-- 編輯出貨資訊 -->
          <!-- 無串接物流的訂單才需顯示 -->
          <div v-if="order.logistics === 6" class="grid grid-cols-2 gap-4 mt-4">
            <div>
              <h1 class="title">物流商</h1>
              <ValidationProvider tag="div" class="relative" name="物流商" rules="max:50" v-slot="{ errors }">
                <vs-input placeholder="" v-model="orderShippingStatuslog.logistics_store" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div>
              <h1 class="title">物流編號</h1>
              <ValidationProvider tag="div" class="relative" name="物流編號" rules="max:50" v-slot="{ errors }">
                <vs-input placeholder="" v-model="orderShippingStatuslog.logistics_num" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div class="col-span-2">
              <h1 class="title">物流連結</h1>
              <vs-input placeholder="" v-model="orderShippingStatuslog.logistics_link" />
            </div>
            <!-- <div>
              <h1 class="title">出貨狀態 *</h1>
              <ValidationProvider tag="div" class="relative"  name="出貨狀態" rules="required" v-slot="{ errors }">
                <vs-select placeholder="請選擇" v-model="orderShippingStatuslog.logistics_status">
                  <vs-option label="已出貨" value="1"> 已出貨 </vs-option>
                </vs-select>
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div> -->
          </div>
        </section>
        <template #footer>
          <div class="flex justify-end space-x-4">
            <vs-button size="large" transparent @click="shipping_Dialog = false">取消</vs-button>
            <vs-button
              @click="setShippingData(invalid)"
              :disabled="invalid"
              size="large"
              transparent
              color="success"
              ref="setShippingDataBtn"
              >出貨</vs-button
            >
          </div>
        </template>
      </vs-dialog>
    </ValidationObserver>

    <!-- 變更已取貨 dialog -->
    <vs-dialog overflow-hidden blur v-model="pickup_Dialog" not-center>
      <template #header>
        <h3 class="h3">變更已取貨</h3>
      </template>
      <section class="text-sm text-gray-text_dark mb-3">
        <p>是否變更訂單狀態為 已取貨？</p>
      </section>
      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="shipping_Dialog = false">取消</vs-button>
          <vs-button @click="pickupMOrder" size="large" transparent color="success" ref="pickupMOrderBtn">確定</vs-button>
        </div>
      </template>
    </vs-dialog>

    <!-- 編輯出貨狀態 dialog -->
    <ValidationObserver v-slot="{ invalid }">
      <vs-dialog overflow-hidden blur v-model="shippingStatus_Dialog" not-center>
        <template #header>
          <h3 class="h3">編輯出貨狀態</h3>
        </template>
        <section class="text-sm text-gray-text_dark mb-3">
          <div class="w-full">
            <!-- 訂單商品標題 -->
            <div class="flex items-center py-2 text-gray-text_dark">
              <p class="product_list_1 in_shipping_content">商品</p>
              <p class="product_list_2 in_shipping_content">數量</p>
            </div>
          </div>
          <!-- 商品列表 -->
          <div class="space-y-4 py-4 w-full border-t-2 border-b-2 border-gray-background">
            <div v-for="(item, index) in orderShippingStatuslog.orderspec_arr" :key="index" class="flex items-center px-4">
              <div class="product_list_1 in_shipping_content flex items-center">
                <!-- 商品圖 -->
                <div class="w-12 h-12 rounded-xl overflow-hidden shadow-md flex-shrink-0">
                  <img v-if="item.img" class="object-cover h-full w-full" :src="item.img" />
                  <svg-icon v-else icon-class="product_tag" className="gray-border sm"></svg-icon>
                </div>
                <!-- 標題 / 規格 / 單價 -->
                <div class="text-left flex-grow px-4" style="max-width: 230px">
                  <p class="text-success hover:underline">{{ item.pname }}</p>
                  <div class="text-xs">
                    <span>{{ item.spec1 }}</span>
                    <span v-if="item.spec2">{{ ' / ' + item.spec2 }}</span>
                    <span v-if="item.spec3">{{ ' / ' + item.spec3 }}</span>
                  </div>
                </div>
              </div>
              <!-- 數量 -->
              <div class="product_list_2 in_shipping_content">
                <span>{{ item.samount }}</span>
              </div>
            </div>
          </div>
          <!-- 編輯出貨資訊 -->
          <div class="grid grid-cols-2 gap-4 mt-4">
            <div>
              <h1 class="title">物流商</h1>
              <ValidationProvider tag="div" class="relative" name="物流商" rules="max:50" v-slot="{ errors }">
                <vs-input placeholder="" v-model="orderShippingStatuslog.logistics_store" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div>
              <h1 class="title">物流編號</h1>
              <ValidationProvider tag="div" class="relative" name="物流編號" rules="max:50" v-slot="{ errors }">
                <vs-input placeholder="" v-model="orderShippingStatuslog.logistics_num" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div>
              <h1 class="title">物流連結</h1>
              <ValidationProvider tag="div" class="relative" name="物流連結" rules="" v-slot="{ errors }">
                <vs-input placeholder="" v-model="orderShippingStatuslog.logistics_link" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div>
              <h1 class="title">出貨狀態 *</h1>
              <ValidationProvider tag="div" class="relative" name="出貨狀態" rules="required" v-slot="{ errors }">
                <vs-select placeholder="請選擇" v-model="orderShippingStatuslog.logistics_status">
                  <vs-option label="未出貨" value="0"> 未出貨 </vs-option>
                  <vs-option label="已出貨" value="1"> 已出貨 </vs-option>
                  <vs-option label="未取貨" value="2"> 未取貨 </vs-option>
                  <vs-option label="已取貨" value="3"> 已取貨 </vs-option>
                </vs-select>
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
          </div>
        </section>

        <template #footer>
          <div class="flex items-center justify-between">
            <vs-button @click="cancelShippingStatusData" ref="cancelShippingStatusDataBtn" size="large" transparent color="danger"
              >取消出貨</vs-button
            >
            <div class="flex justify-end space-x-4">
              <vs-button size="large" transparent @click="shippingStatus_Dialog = false">取消</vs-button>
              <vs-button
                @click="setShippingStatusData(invalid)"
                :disabled="invalid"
                size="large"
                transparent
                color="success"
                ref="setShippingStatusDataBtn"
                >儲存</vs-button
              >
            </div>
          </div>
        </template>
      </vs-dialog>
    </ValidationObserver>

    <!-- 編輯手動退貨 dialog -->
    <ValidationObserver v-slot="{ invalid }">
      <vs-dialog overflow-hidden blur v-model="return_Dialog" not-center>
        <template #header>
          <h3 class="h3">訂單退貨</h3>
        </template>
        <section class="text-sm text-gray-text_dark mb-3">
          <div class="w-full">
            <!-- 訂單商品標題 -->
            <div class="flex items-center py-2 text-gray-text_dark">
              <p class="product_list_1 in_shipping_content">商品</p>
              <p class="product_list_2 in_shipping_content">數量</p>
            </div>
          </div>
          <!-- 商品列表 -->
          <div class="space-y-4 py-4 w-full border-t-2 border-b-2 border-gray-background">
            <div v-for="(item, index) in orderReturnlog" :key="index" class="space-y-2">
              <div class="flex items-center">
                <div class="product_list_1 in_shipping_content flex items-center">
                  <!-- 商品圖 -->
                  <div class="w-12 h-12 rounded-xl overflow-hidden shadow-md flex-shrink-0">
                    <img v-if="item.img" class="object-cover h-full w-full" :src="item.img" />
                    <svg-icon v-else icon-class="product_tag" className="gray-border sm"></svg-icon>
                  </div>
                  <!-- 標題 / 規格 / 單價 -->
                  <div class="text-left flex-grow px-4" style="max-width: 230px">
                    <p class="text-success hover:underline">{{ item.pname }}</p>
                    <div class="text-xs">
                      <span>{{ item.spec1 }}</span>
                      <span v-if="item.spec2">{{ ' / ' + item.spec2 }}</span>
                      <span v-if="item.spec3">{{ ' / ' + item.spec3 }}</span>
                    </div>
                  </div>
                </div>
                <!-- 數量 -->
                <ValidationProvider
                  tag="div"
                  class="relative flex-shrink-0 product_list_2 in_shipping_content"
                  name="數量"
                  :rules="`required|numeric|min_value:0|max_value:${item.max_ramount2}`"
                  v-slot="{ errors }"
                >
                  <div class="flex items-center justify-between">
                    <vs-input placeholder="必填" v-model="item.ramount2" style="width: 70px" />
                    <span>of {{ item.max_ramount2 }}</span>
                  </div>

                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
              </div>

              <div class="w-full">
                <ValidationProvider tag="div" class="relative" name="退貨說明" rules="max:200" v-slot="{ errors }">
                  <vs-input placeholder="退貨說明" v-model="item.rnote" />
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </section>
        <template #footer>
          <div class="flex justify-end space-x-4">
            <vs-button size="large" transparent @click="return_Dialog = false">取消</vs-button>
            <vs-button @click="setReurnData(invalid)" :disabled="invalid" size="large" transparent color="success" ref="setReurnDataBtn"
              >儲存</vs-button
            >
          </div>
        </template>
      </vs-dialog>
    </ValidationObserver>

    <!-- 編輯訂單備註 dialog -->
    <ValidationObserver v-slot="{ invalid }">
      <vs-dialog overflow-hidden blur v-model="onote_Dialog" not-center>
        <template #header>
          <h1 class="text-base font-medium text-gray-text_dark">編輯訂單備註</h1>
        </template>

        <div class="mb-3 text-sm text-gray-text_light">
          <ValidationProvider tag="div" class="relative" name="備註" rules="max:200" v-slot="{ errors }">
            <myTextarea v-model="orderlog.onote"></myTextarea>
            <errorDiv>{{ errors[0] }}</errorDiv>
          </ValidationProvider>
        </div>

        <template #footer>
          <div class="flex justify-end space-x-4">
            <vs-button size="large" transparent @click="onote_Dialog = false">取消</vs-button>
            <vs-button @click="saveONote(invalid)" :disabled="invalid" size="large" transparent color="success" ref="saveONoteBtn"
              >儲存</vs-button
            >
          </div>
        </template>
      </vs-dialog>
    </ValidationObserver>

    <!-- 編輯購買人 dialog -->
    <ValidationObserver v-slot="{ invalid }">
      <vs-dialog overflow-hidden blur v-model="bdata_Dialog" not-center>
        <template #header>
          <h3 class="h3">編輯購買人資料</h3>
        </template>

        <section class="space-y-4">
          <div>
            <h1 class="title">姓名 *</h1>
            <ValidationProvider tag="div" class="relative" name="姓名" rules="required|max:30" v-slot="{ errors }">
              <vs-input placeholder="必填" v-model="orderlog.uname" />
              <errorDiv>{{ errors[0] }}</errorDiv>
            </ValidationProvider>
          </div>
          <div>
            <h1 class="title">手機號碼 *</h1>
            <div class="flex space-x-2">
              <div>
                <vs-select placeholder="請選擇區碼" filter v-model="mp_code" v-if="countries.length > 0">
                  <vs-option
                    v-for="country in countries"
                    :key="country.en"
                    :label="`${country.code} (${country.cn} ${country.en})`"
                    :value="`${country.code},${country.en}`"
                    >{{ country.code }} ({{ country.cn }})</vs-option
                  >
                </vs-select>
              </div>
              <div>
                <ValidationProvider tag="div" class="relative" name="手機號碼" rules="numeric|max:20" v-slot="{ errors }">
                  <vs-input placeholder="不需再輸入區碼，例如：920456789" v-model="orderlog.mp" />
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div>
            <h1 class="title">信箱 *</h1>
            <ValidationProvider tag="div" class="relative" name="信箱" rules="required|max:150|email" v-slot="{ errors }">
              <vs-input placeholder="必填" v-model="orderlog.email" />
              <errorDiv>{{ errors[0] }}</errorDiv>
            </ValidationProvider>
          </div>
        </section>
        <template #footer>
          <div class="flex justify-end space-x-4">
            <vs-button size="large" transparent @click="bdata_Dialog = false">取消</vs-button>
            <vs-button @click="saveBData(invalid)" :disabled="invalid" size="large" transparent color="success" ref="saveBDataBtn"
              >儲存</vs-button
            >
          </div>
        </template>
      </vs-dialog>
    </ValidationObserver>

    <!-- 編輯收件人 dialog -->
    <ValidationObserver v-slot="{ invalid }">
      <vs-dialog overflow-hidden blur v-model="rdata_Dialog" not-center>
        <template #header>
          <h3 class="h3">編輯收件人資料</h3>
        </template>

        <section class="space-y-4">
          <div class="flex space-x-4">
            <vs-button size="large" flat :active="logisticsType == 0" @click="logisticsType = 0"> 宅配 </vs-button>
            <vs-button size="large" flat :active="logisticsType == 1" @click="logisticsType = 1"> 超商取貨 </vs-button>
          </div>
          <div>
            <h1 class="title">姓名 *</h1>
            <ValidationProvider tag="div" class="relative" name="姓名" rules="required|max:30" v-slot="{ errors }">
              <vs-input placeholder="必填" v-model="orderlog.runame" />
              <errorDiv>{{ errors[0] }}</errorDiv>
            </ValidationProvider>
          </div>
          <div>
            <h1 class="title">手機號碼 *</h1>
            <div class="flex space-x-2">
              <div class="w-1/3">
                <vs-select placeholder="請選擇區碼" filter v-model="mp_code" v-if="countries.length > 0">
                  <vs-option
                    v-for="country in countries"
                    :key="country.en"
                    :label="`${country.code} (${country.cn} ${country.en})`"
                    :value="`${country.code},${country.en}`"
                    >{{ country.code }} ({{ country.cn }})</vs-option
                  >
                </vs-select>
              </div>
              <div class="w-2/3">
                <ValidationProvider tag="div" class="relative" name="手機號碼" rules="required|numeric|max:20" v-slot="{ errors }">
                  <vs-input placeholder="不需再輸入區碼，例如：920456789" v-model="orderlog.rmp" />
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div>
            <h1 class="title">信箱 *</h1>
            <ValidationProvider tag="div" class="relative" name="信箱" rules="required|max:150|email" v-slot="{ errors }">
              <vs-input placeholder="必填" v-model="orderlog.remail" />
              <errorDiv>{{ errors[0] }}</errorDiv>
            </ValidationProvider>
          </div>
          <div class="grid grid-cols-2 gap-4" v-if="logisticsType == 0">
            <div>
              <h1 class="title">國家/地區 *</h1>
              <vs-select placeholder="搜尋國家/地區" filter v-model="orderlog.rshit" v-if="countries.length > 0">
                <vs-option
                  v-for="country in countries"
                  :key="country.en"
                  :label="country.cn + ' ' + country.en"
                  :value="country.cn + ' ' + country.en"
                  >{{ country.cn }} {{ country.en }}</vs-option
                >
              </vs-select>
            </div>
            <div>
              <h1 class="title">縣市 *</h1>
              <!-- 縣市(若為台灣，選單) -->
              <ValidationProvider
                v-if="orderlog.rshit === '台灣 Taiwan'"
                tag="div"
                class="relative"
                name="縣市"
                rules="required|max:20"
                v-slot="{ errors }"
              >
                <vs-select @change="cityHandler" class="w-full" v-model="orderlog.rcity">
                  <vs-option v-for="city in TW_districts" :key="city.name" :label="city.name" :value="city.name">{{ city.name }}</vs-option>
                </vs-select>
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>

              <ValidationProvider v-else tag="div" class="relative" name="縣市" rules="required|max:20" v-slot="{ errors }">
                <vs-input placeholder="必填" v-model="orderlog.rcity" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div>
              <h1 class="title">鄉鎮市區 *</h1>

              <!-- 鄉鎮市區(若為台灣，選單) -->

              <ValidationProvider
                v-if="_district && orderlog.rshit === '台灣 Taiwan'"
                tag="div"
                class="relative"
                name="鄉鎮市區"
                rules="required|max:20"
                v-slot="{ errors }"
              >
                <vs-select :key="_district.districts.length" @change="districtHandler" class="w-full" v-model="orderlog.rarea">
                  <vs-option v-for="district in _district.districts" :key="district.name" :label="district.name" :value="district.name">{{
                    district.name
                  }}</vs-option>
                </vs-select>
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>

              <ValidationProvider v-else tag="div" class="relative" name="鄉鎮市區" rules="required|max:20" v-slot="{ errors }">
                <vs-input placeholder="必填" v-model="orderlog.rarea" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div>
              <h1 class="title">郵遞區號 *</h1>
              <ValidationProvider tag="div" class="relative" name="郵遞區號" rules="required|max:20" v-slot="{ errors }">
                <vs-input placeholder="必填" v-model="orderlog.rzipcode" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div>
              <h1 class="title">地址一 *</h1>
              <ValidationProvider tag="div" class="relative" name="地址一" rules="required|max:100" v-slot="{ errors }">
                <vs-input placeholder="必填" v-model="orderlog.raddress1" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div>
              <h1 class="title">地址二</h1>
              <ValidationProvider tag="div" class="relative" name="地址二" rules="max:100" v-slot="{ errors }">
                <vs-input v-model="orderlog.raddress2" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
          </div>
          <div>
            <h1 class="title">運送方式 *</h1>
            <div v-if="logisticsType == 0" class="flex flex-col items-start py-2 text-sm text-gray-text_light space-y-2">
              <vs-radio
                v-for="item in ptermsLogistics0"
                :key="item.slno"
                @input="logisticsRadioHandler(item)"
                v-model="logisticsKey"
                :val="item.slno"
              >
                <div class="flex items-center justify-between space-x-4">
                  <p>{{ item.title }}</p>
                  <p>NT${{ item.lpays | currency }}</p>
                </div>
              </vs-radio>
            </div>
            <div v-if="logisticsType == 1" class="flex flex-col items-start py-2 text-sm text-gray-text_light space-y-2">
              <div v-for="item in ptermsLogistics1" :key="item.slno" class="py-1">
                <vs-radio @input="logisticsRadioHandler(item)" v-model="logisticsKey" :val="item.slno">
                  <div class="flex items-center justify-between space-x-4">
                    <p class="mr-5">{{ item.title }}</p>
                    <p>NT${{ item.lpays | currency }}</p>
                  </div>
                </vs-radio>
              </div>
            </div>
          </div>
          <div class="space-y-4" v-if="logisticsType == 1">
            <div class="text-sm">
              <h1 class="title">超商門市 *</h1>
              <div class="flex space-x-2">
                <div class="w-1/3">
                  <ValidationProvider tag="div" class="relative" name="門市" rules="required|max:30" v-slot="{ errors }">
                    <vs-input placeholder="門市必填" v-model="orderlog.store_name" disabled />
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
                <div class="w-1/3">
                  <ValidationProvider tag="div" class="relative" name="店號" rules="required|max:10" v-slot="{ errors }">
                    <vs-input placeholder="店號必填" v-model="orderlog.store_id" disabled />
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
                <div class="w-1/3">
                  <button class="bg-success text-white w-full h-10 rounded-xl" @click="getStorePopup">
                    <span>選擇超商門市</span>
                  </button>
                </div>
              </div>
            </div>
            <!-- <div>
              <h1 class="title">規格</h1>
              <vs-select placeholder="Select" v-model="orderlog.spec">
                <vs-option label="60cm" value="1"> 60cm </vs-option>
                <vs-option label="90cm" value="2"> 90cm </vs-option>
                <vs-option label="120cm" value="3"> 120cm </vs-option>
                <vs-option label="150cm" value="4"> 150cm </vs-option>
              </vs-select>
            </div>
            <div>
              <h1 class="title">送貨時間</h1>
              <vs-select placeholder="Select" v-model="orderlog.ltime">
                <vs-option label="不分時段" value="0"> 不分時段 </vs-option>
                <vs-option label="中午前" value="1"> 中午前 </vs-option>
                <vs-option label="12時=>17" value="2"> 12時=>17 </vs-option>
                <vs-option label="17時=>20時" value="3"> 17時=>20時 </vs-option>
              </vs-select>
            </div> -->
          </div>
        </section>

        <template #footer>
          <div class="flex justify-end space-x-4">
            <vs-button size="large" transparent @click="rdata_Dialog = false">取消</vs-button>
            <vs-button @click="saveRData(invalid)" :disabled="invalid" size="large" transparent color="success" ref="saveRDataBtn"
              >儲存</vs-button
            >
          </div>
        </template>
      </vs-dialog>
    </ValidationObserver>

    <!-- 編輯付款方式 dialog -->
    <ValidationObserver v-slot="{ invalid }">
      <vs-dialog overflow-hidden blur v-model="pterms_Dialog" not-center>
        <template #header>
          <h3 class="h3">編輯付款方式</h3>
        </template>
        <div class="mb-3 text-gray-text_light text-sm" v-if="orderlog.pterms !== 6">
          <section class="space-y-2 flex flex-col items-start">
            <div v-for="item in ptermsLogistics.arr1" :key="item.spno" class="">
              <vs-radio @input="ptermsRadioHandler(item)" v-model="ptermsKey" :val="item.spno">
                <span class="mr-5">{{ item.title }}</span>
              </vs-radio>
            </div>
          </section>
        </div>
        <template #footer>
          <div class="flex justify-end space-x-4" v-if="orderlog.pterms !== 6">
            <vs-button size="large" transparent @click="pterms_Dialog = false">取消</vs-button>
            <vs-button @click="savePtermsData(invalid)" :disabled="invalid" size="large" transparent color="success" ref="savePtermsDataBtn"
              >儲存</vs-button
            >
          </div>
        </template>
      </vs-dialog>
    </ValidationObserver>

    <!-- 編輯退款資訊 dialog -->
    <ValidationObserver v-slot="{ invalid }">
      <vs-dialog overflow-hidden blur v-model="refund_Dialog" not-center>
        <template #header>
          <h3 class="h3">訂單退款</h3>
          <span class="text-gray-400 h4">※只可退款一次</span>
        </template>

        <section class="mb-3 text-gray-text_dark text-sm space-y-4">
          <div>
            <h1 class="title">退款金額 *</h1>
            <ValidationProvider
              tag="div"
              class="relative"
              name="退款金額"
              :rules="`required|numeric|min_value:1|max_value:${order.opays}`"
              v-slot="{ errors }"
            >
              <div class="flex items-center space-x-2">
                <span>NT$</span>
                <vs-input class="w-full" placeholder="必填" v-model="refundlog.rrefund" />
              </div>
              <errorDiv>{{ errors[0] }}</errorDiv>
            </ValidationProvider>
          </div>
          <div v-if="order.smno">
            <h1 class="title">退購物金</h1>
            <ValidationProvider
              tag="div"
              class="relative"
              name="退購物金"
              :rules="`numeric|min_value:0|max_value:${order.opays}`"
              v-slot="{ errors }"
            >
              <vs-input placeholder="" v-model="refundlog.repoint1" />
              <errorDiv>{{ errors[0] }}</errorDiv>
            </ValidationProvider>
          </div>
          <div>
            <h1 class="title">備註</h1>
            <ValidationProvider tag="div" class="relative" name="備註" rules="max:200" v-slot="{ errors }">
              <vs-input placeholder="" v-model="refundlog.rnote" />
              <errorDiv>{{ errors[0] }}</errorDiv>
            </ValidationProvider>
          </div>
        </section>

        <template #footer>
          <div class="flex justify-end space-x-4">
            <vs-button size="large" transparent @click="refund_Dialog = false">取消</vs-button>
            <vs-button
              @click="saveRefundMOrder(invalid)"
              :disabled="invalid"
              size="large"
              transparent
              color="success"
              ref="saveRefundMOrderBtn"
              >儲存</vs-button
            >
          </div>
        </template>
      </vs-dialog>
    </ValidationObserver>

    <!-- 編輯發票資訊 dialog -->
    <ValidationObserver v-slot="{ invalid }">
      <vs-dialog overflow-hidden blur v-model="invoice_Dialog" not-center>
        <template #header>
          <h3 class="h3">編輯發票資訊</h3>
        </template>

        <section class="grid grid-cols-1 gap-4">
          <div>
            <h1 class="title">發票格式</h1>
            <div class="flex space-x-4">
              <vs-select v-model="orderlog.intype1">
                <vs-option label="紙本發票" :value="0"> 紙本發票 </vs-option>
                <vs-option label="捐贈發票" :value="1"> 捐贈發票 </vs-option>
                <vs-option label="電子發票" :value="2"> 電子發票 </vs-option>
              </vs-select>
              <vs-select v-model="orderlog.intype2">
                <vs-option label="無" :value="0"> 無 </vs-option>
                <vs-option label="2聯式" :value="2"> 2聯式 </vs-option>
                <vs-option label="3聯式" :value="3"> 3聯式 </vs-option>
              </vs-select>
            </div>
          </div>
          <div>
            <h1 class="title">公司抬頭</h1>
            <ValidationProvider tag="div" class="relative" name="公司抬頭" rules="max:30" v-slot="{ errors }">
              <vs-input placeholder="" v-model="orderlog.cname" />
              <errorDiv>{{ errors[0] }}</errorDiv>
            </ValidationProvider>
          </div>
          <div>
            <h1 class="title">統一編號</h1>
            <ValidationProvider tag="div" class="relative" name="統一編號" rules="max:8" v-slot="{ errors }">
              <vs-input placeholder="" v-model="orderlog.cvat" />
              <errorDiv>{{ errors[0] }}</errorDiv>
            </ValidationProvider>
          </div>
          <div>
            <h1 class="title">發票號碼</h1>
            <ValidationProvider tag="div" class="relative" name="發票號碼" rules="max:15" v-slot="{ errors }">
              <vs-input placeholder="" v-model="orderlog.invoice" />
              <errorDiv>{{ errors[0] }}</errorDiv>
            </ValidationProvider>
          </div>
        </section>

        <template #footer>
          <div class="flex justify-end space-x-4">
            <vs-button size="large" transparent @click="invoice_Dialog = false">取消</vs-button>
            <vs-button @click="saveInvoice(invalid)" :disabled="invalid" size="large" transparent color="success" ref="saveInvoiceBtn"
              >儲存</vs-button
            >
          </div>
        </template>
      </vs-dialog>
    </ValidationObserver>

    <!-- 編輯商家備註 dialog -->
    <ValidationObserver v-slot="{ invalid }">
      <vs-dialog overflow-hidden blur v-model="snote_Dialog" not-center>
        <template #header>
          <h3 class="h3">編輯商家備註</h3>
        </template>

        <div class="flex flex-col my-4">
          <ValidationProvider tag="div" class="relative" name="備註" rules="max:200" v-slot="{ errors }">
            <vs-input placeholder="" v-model="orderlog.snote" />
            <errorDiv>{{ errors[0] }}</errorDiv>
          </ValidationProvider>
        </div>

        <template #footer>
          <div class="flex justify-end space-x-4">
            <vs-button size="large" transparent @click="snote_Dialog = false">取消</vs-button>
            <vs-button @click="saveSNote(invalid)" :disabled="invalid" size="large" transparent color="success" ref="saveSNoteBtn"
              >儲存</vs-button
            >
          </div>
        </template>
      </vs-dialog>
    </ValidationObserver>

    <!-- 編輯變更已付款 dialog -->
    <ValidationObserver v-slot="{ invalid }">
      <vs-dialog overflow-hidden blur v-model="payed_Dialog" not-center>
        <template #header>
          <h3 class="h3">變更已付款</h3>
        </template>
        <div class="mb-3 text-sm text-gray-text_dark">
          <p>是否變更訂單狀態為 已付款？</p>
          <!-- <div class="flex items-center justify-between"> -->
          <!-- <h1 class="title">付款方式</h1> -->
          <!-- <vs-button size="mini" transparent color="success" @click="getPtermsLogisticsList(1)">
              <span class="font-medium text-success text-sm">編輯</span>
            </vs-button> -->
          <!-- </div> -->
          <!-- <p class="break_word">{{ pthirdMixin(order.pthird) }} {{ ptermsMixin(order.pterms) }}</p> -->
        </div>
        <template #footer>
          <div class="flex justify-end space-x-4">
            <vs-button size="large" transparent @click="payed_Dialog = false">取消</vs-button>
            <vs-button
              @click="savegetMOrderPayed(invalid)"
              :disabled="invalid"
              size="large"
              transparent
              color="success"
              ref="savegetMOrderPayedBtn"
              >確定</vs-button
            >
          </div>
        </template>
      </vs-dialog>
    </ValidationObserver>

    <!-- 編輯取消備註 dialog -->
    <ValidationObserver v-slot="{ invalid }">
      <vs-dialog overflow-hidden blur v-model="cancel_Dialog" not-center>
        <template #header>
          <h3 class="h3">編輯取消訂單</h3>
        </template>

        <div class="flex flex-col my-4">
          <h1 class="title">備註 *</h1>
          <ValidationProvider tag="div" class="relative" name="備註" rules="required|max:200" v-slot="{ errors }">
            <vs-input placeholder="必填" v-model="cancel_note" />
            <errorDiv>{{ errors[0] }}</errorDiv>
          </ValidationProvider>
        </div>

        <template #footer>
          <div class="flex justify-end space-x-4">
            <vs-button size="large" transparent @click="cancel_Dialog = false">取消</vs-button>
            <vs-button
              @click="saveCancelMOrder(invalid)"
              :disabled="invalid"
              size="large"
              transparent
              color="success"
              ref="saveCancelMOrderBtn"
              >儲存</vs-button
            >
          </div>
        </template>
      </vs-dialog>
    </ValidationObserver>
  </div>
</template>

<script>
import TW_districts from '@/assets/json/TW_districts.json' // 台灣縣市 郵遞區號 列表
import Accordion from '@/components/ui_components/accordion'
import AccordionItem from '@/components/ui_components/accordion-item'
import countries from '@/assets/json/en_zh_country.json'
import orderStatus from '@/assets/mixins/orderStatus.js'
import dropdown from '@/components/dropdown'

export default {
  name: 'order',
  components: {
    dropdown,
    Accordion,
    AccordionItem,
  },
  mixins: [orderStatus],
  data() {
    return {
      TW_districts: TW_districts, // 台灣縣市 郵遞區號 列表
      is_API_loaded: false,
      order: {},
      orderlog: {
        land: '',
        logistics: '',
        lthird: '',
        ltime: '',
        pterms: '',
        pthird: '',
        raddress1: '',
        raddress2: '',
        rarea: '',
        rcity: '',
        remail: '',
        rmp: '',
        rshit: '',
        runame: '',
        rzipcode: '',
        spec: '',
        store_id: '',
        store_name: '',
        temp: '',
        intype1: '',
        intype2: '',
        cname: '',
        cvat: '',
        invoice: '',
      },
      orderShippinglog: {},
      orderShippingStatuslog: {
        logistics_store: '',
        logistics_num: '',
        logistics_link: '',
        logistics_status: '',
      },
      orderReturnlog: {},
      ptermsLogistics: {},
      ptermsLogistics0: {},
      ptermsLogistics1: {},
      refundlog: { rrefund: 0, repoint1: '', rnote: '' },
      dropdownIndex: 0,
      countries: countries,
      mp_code: '+886,Taiwan',
      rmp_code: '+886,Taiwan',
      ptermsKey: '',
      logisticsType: 0, // 0宅配1超商
      logisticsKey: '',
      shipping_Dialog: false,
      shippingStatus_Dialog: false,
      return_Dialog: false,
      onote_Dialog: false,
      bdata_Dialog: false,
      rdata_Dialog: false,
      pterms_Dialog: false,
      invoice_Dialog: false,
      snote_Dialog: false,
      refund_Dialog: false,
      payed_Dialog: false,
      cancel_Dialog: false,
      pickup_Dialog: false,
      cancel_note: '',
      bstatus: {
        shippingStatus: false, //出貨
        payedStatus: false, //變更已付款
        pickupStatus: false, //變更已取貨
        returnStatus: false, //訂單退貨
        refundStatus: false, //訂單退款
        cancelStatus: false, //取消訂單
        rdataStatus: false, //收件人編輯
        ptermsStatus: false, //付款方式
      },
    }
  },
  created() {
    this.loadOrder()
    window.addEventListener('message', this.getStoreData)
    // 監聽點擊，實現點擊dropdown之外的地方，關閉dropdown
    document.addEventListener('click', this.mouseClick)
  },
  computed: {
    // 篩選出鄉鎮市區
    _district() {
      let city = this.orderlog.rcity

      if (city) return this.TW_districts.find((item) => item.name === city)
      else return null
    },
  },
  methods: {
    // 去除首字 0
    removeMpZero(value) {
      if (value.substr(0, 1) === '0') {
        return value.slice(1)
      } else return value
    },
    mouseClick(e) {
      this.dropdownIndex = null
    },
    dropdownClick(index) {
      // 如果是開著，就關閉
      if (this.dropdownIndex) {
        this.dropdownIndex = null
      } else {
        // 設定setTimeout 比mouseClick晚觸發
        setTimeout(() => {
          this.dropdownIndex = index
        }, 0)
      }
    },
    // 3.3.1.2 訂單資料-讀取
    loadOrder() {
      this.$axios({
        url: 'front/store/morder/getMOrderUnit.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          smono: this.$route.params.id,
        },
      }).then((res) => {
        console.log(res, '3.3.1.2 訂單資料-讀取')
        if (res.data.Success) {
          this.order = res.data.Data
          if (this.order.logistics == 1 || this.order.logistics == 2 || this.order.logistics == 3 || this.order.logistics == 4) {
            this.logisticsType = 1
          }
          //出貨
          if (
            this.order.ostatus == 0 &&
            (this.order.pstatus == 0 || this.order.pstatus == 1 || this.order.pstatus == 2) &&
            (this.order.lstatus == 0 || this.order.lstatus == 1)
          ) {
            this.bstatus.shippingStatus = true
          } else this.bstatus.shippingStatus = false
          //變更已付款
          if (this.order.ostatus == 0 && (this.order.pstatus == 0 || this.order.pstatus == 1)) {
            this.bstatus.payedStatus = true
          } else this.bstatus.payedStatus = false
          //變更已取貨
          if (this.order.ostatus == 0 && (this.order.lstatus == 1 || this.order.lstatus == 2)) {
            this.bstatus.pickupStatus = true
          } else this.bstatus.pickupStatus = false
          //訂單退貨
          if (this.order.ostatus == 0 && (this.order.lstatus == 4 || this.order.lstatus == 5)) {
            this.bstatus.returnStatus = true
          } else this.bstatus.returnStatus = false
          //訂單退款
          if (this.order.ostatus == 0 && this.order.pstatus == 2) {
            this.bstatus.refundStatus = true
          } else this.bstatus.refundStatus = false
          //取消訂單
          if (this.order.ostatus == 0 && this.order.pstatus == 0 && this.order.lstatus == 0) {
            this.bstatus.cancelStatus = true
          } else this.bstatus.cancelStatus = false
          //收件人編輯
          if (this.order.ostatus == 0 && (this.order.lstatus == 0 || this.order.lstatus == 1)) {
            this.bstatus.rdataStatus = true
          } else this.bstatus.rdataStatus = false
          //付款方式
          if (this.order.ostatus == 0 && (this.order.pstatus == 0 || this.order.pstatus == 1)) {
            this.bstatus.ptermsStatus = true
          } else this.bstatus.ptermsStatus = false
          this.is_API_loaded = true
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    //出貨
    getShippingData() {
      let arr = new Array()
      for (let i = 0; i < this.order.orderspec_arr.length; i++) {
        let obj = new Object()
        obj.index = i
        obj.smoino = this.order.orderspec_arr[i].smoino
        obj.img = this.order.orderspec_arr[i].img
        obj.pname = this.order.orderspec_arr[i].pname
        obj.spec1 = this.order.orderspec_arr[i].spec1
        obj.spec2 = this.order.orderspec_arr[i].spec2
        obj.spec3 = this.order.orderspec_arr[i].spec3
        obj.samount2 = this.order.orderspec_arr[i].samount2
        obj.max_samount2 = this.order.orderspec_arr[i].samount2
        if (obj.max_samount2 != 0) arr.push(obj)
      }
      this.orderShippinglog = arr
      this.orderShippingStatuslog.logistics_store = ''
      this.orderShippingStatuslog.logistics_num = ''
      this.orderShippingStatuslog.logistics_link = ''
      this.orderShippingStatuslog.logistics_status = '1' // 出貨預設已出貨
      this.shipping_Dialog = true
    },
    setShippingData(invalid) {
      if (invalid) return
      const loading = this.$vs.loading({
        target: this.$refs.setShippingDataBtn,
        background: 'success',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      this.$axios({
        url: 'front/store/morder/uMOrderShipping.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          smono: this.$route.params.id,
          djson: JSON.stringify(this.orderShippinglog),
        },
      }).then((res) => {
        if (res.data.Success) {
          // 關閉按鈕loading
          loading.close()
          this.shipping_Dialog = false
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.loadOrder()
          this.setLogisticsThirdApi()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    //出貨狀態
    getShippingStatusData(smolno, index) {
      const loading = this.$vs.loading({
        target: this.$refs['getShippingStatusDataBtn' + index][0],
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      this.$axios({
        url: 'front/store/morder/getMOrderShippingLog.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          smono: this.$route.params.id,
          smolno: smolno,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.orderShippingStatuslog = res.data.Data
          this.shippingStatus_Dialog = true
          this.loadOrder()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
        loading.close()
      })
    },
    // 儲存編輯出貨狀態資訊
    setShippingStatusData(invalid) {
      if (invalid) return
      const loading = this.$vs.loading({
        target: this.$refs.setShippingStatusDataBtn,
        background: 'success',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      this.$axios({
        url: 'front/store/morder/uMOrderShippingLog.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          smono: this.$route.params.id,
          smolno: this.orderShippingStatuslog.smolno,
          logistics_store: this.orderShippingStatuslog.logistics_store,
          logistics_num: this.orderShippingStatuslog.logistics_num,
          logistics_link: this.orderShippingStatuslog.logistics_link,
          logistics_status: this.orderShippingStatuslog.logistics_status,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.shippingStatus_Dialog = false
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.loadOrder()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
        loading.close()
      })
    },
    // 取消出貨
    cancelShippingStatusData() {
      const loading = this.$vs.loading({
        target: this.$refs.cancelShippingStatusDataBtn,
        background: 'danger',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      this.$axios({
        url: 'front/store/morder/cancelMOrderShippingLog.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          smono: this.$route.params.id,
          smolno: this.orderShippingStatuslog.smolno,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.shippingStatus_Dialog = false
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.loadOrder()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
        loading.close()
      })
    },
    //訂單退貨
    getReturnMOrder() {
      let arr = new Array()
      for (let i = 0; i < this.order.orderspec_arr.length; i++) {
        let obj = new Object()
        obj.index = i
        obj.smoino = this.order.orderspec_arr[i].smoino
        obj.img = this.order.orderspec_arr[i].img
        obj.pname = this.order.orderspec_arr[i].pname
        obj.spec1 = this.order.orderspec_arr[i].spec1
        obj.spec2 = this.order.orderspec_arr[i].spec2
        obj.spec3 = this.order.orderspec_arr[i].spec3
        obj.ramount2 = this.order.orderspec_arr[i].ramount2
        obj.max_ramount2 = this.order.orderspec_arr[i].ramount2
        obj.rnote = ''
        if (obj.max_ramount2 != 0) arr.push(obj)
      }
      this.orderReturnlog = arr
      this.return_Dialog = true
    },
    setReurnData(invalid) {
      if (invalid) return
      const loading = this.$vs.loading({
        target: this.$refs.setReurnDataBtn,
        background: 'success',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      this.$axios({
        url: 'front/store/morder/uMOrderReturn.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          smono: this.$route.params.id,
          djson: JSON.stringify(this.orderReturnlog),
        },
      }).then((res) => {
        if (res.data.Success) {
          // 關閉按鈕loading
          loading.close()
          this.return_Dialog = false
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.loadOrder()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    //購買人資料
    getBData() {
      let mp = this.order.mp
      if (mp) {
        for (let i = 5; i > 1; i--) {
          // 迴圈四次 i =  5,4,3,2
          let confirm = false
          let code = mp.substr(0, i) // 取得+區碼四位 例如 +1876 牙買加
          this.countries.forEach((country) => {
            if (country.code === code) {
              confirm = true
              this.mp_code = `${country.code},${country.en}` // 取得配對到的國家
              mp = mp.replace(code, '') // 去除區碼
            }
          })
          if (confirm) {
            break
          }
        }
      }
      this.orderlog.uname = this.order.uname
      this.orderlog.mp = this.removeMpZero(mp)
      this.orderlog.email = this.order.email
      this.bdata_Dialog = true
    },
    saveBData(invalid) {
      if (invalid) return
      const loading = this.$vs.loading({
        target: this.$refs.saveBDataBtn,
        background: 'success',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      this.$axios({
        url: 'front/store/morder/uMOrderBData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          smono: this.$route.params.id,
          uname: this.orderlog.uname,
          mp: this.mp_code.split(',')[0] + this.orderlog.mp,
          email: this.orderlog.email,
        },
      }).then((res) => {
        if (res.data.Success) {
          // 關閉按鈕loading
          loading.close()
          this.bdata_Dialog = false
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.loadOrder()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    getPtermsLogisticsList(num) {
      const loading = this.$vs.loading({
        target: this.$refs['getPtermsLogisticsListBtn' + num],
        background: 'success',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.orderlog.pthird = this.order.pthird
      this.orderlog.pterms = this.order.pterms
      this.orderlog.lthird = this.order.lthird
      this.orderlog.logistics = this.order.logistics
      this.orderlog.temp = this.order.temp
      this.orderlog.spec = this.order.spec
      this.orderlog.ltime = this.order.ltime
      this.orderlog.store_name = this.order.store_name
      this.orderlog.store_id = this.order.store_id

      this.$axios({
        url: 'front/store/pterms/getPtermsLogisticsList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.ptermsLogistics = res.data.Data
          let j = 0
          let k = 0
          let chk = false
          for (let i = 0; i < this.ptermsLogistics.arr2.length; i++) {
            if (
              this.ptermsLogistics.arr2[i].logistics == 5 ||
              this.ptermsLogistics.arr2[i].logistics == 6 ||
              this.ptermsLogistics.arr2[i].logistics == 7
            ) {
              //儲存宅配
              this.ptermsLogistics0[j] = this.ptermsLogistics.arr2[i]
              j++
            } else if (
              this.ptermsLogistics.arr2[i].logistics == 1 ||
              this.ptermsLogistics.arr2[i].logistics == 2 ||
              this.ptermsLogistics.arr2[i].logistics == 3 ||
              this.ptermsLogistics.arr2[i].logistics == 4 ||
              this.ptermsLogistics.arr2[i].lthird == 2
            ) {
              //儲存超商
              this.ptermsLogistics1[k] = this.ptermsLogistics.arr2[i]
              k++
            }
            if (this.orderlog.lthird == 2) {
              //ezship
              if (this.orderlog.pterms == 6 && !chk) {
                //貨到付款
                if (
                  this.ptermsLogistics.arr2[i].pterms == this.orderlog.pterms &&
                  this.ptermsLogistics.arr2[i].lthird == this.orderlog.lthird &&
                  this.ptermsLogistics.arr2[i].temp == this.orderlog.temp
                ) {
                  this.logisticsKey = this.ptermsLogistics.arr2[i].slno
                  chk = true
                }
              } else if (!chk) {
                if (
                  this.ptermsLogistics.arr2[i].lthird == this.orderlog.lthird &&
                  this.ptermsLogistics.arr2[i].temp == this.orderlog.temp
                ) {
                  this.logisticsKey = this.ptermsLogistics.arr2[i].slno
                  chk = true
                }
              }
            } else {
              if (this.orderlog.pterms == 6 && !chk) {
                //貨到付款
                if (
                  this.ptermsLogistics.arr2[i].pterms == this.orderlog.pterms &&
                  this.ptermsLogistics.arr2[i].lthird == this.orderlog.lthird &&
                  this.ptermsLogistics.arr2[i].logistics == this.orderlog.logistics &&
                  this.ptermsLogistics.arr2[i].temp == this.orderlog.temp
                ) {
                  this.logisticsKey = this.ptermsLogistics.arr2[i].slno
                  chk = true
                }
              } else if (!chk) {
                if (
                  this.ptermsLogistics.arr2[i].lthird == this.orderlog.lthird &&
                  this.ptermsLogistics.arr2[i].logistics == this.orderlog.logistics &&
                  this.ptermsLogistics.arr2[i].temp == this.orderlog.temp
                ) {
                  this.logisticsKey = this.ptermsLogistics.arr2[i].slno
                  chk = true
                }
              }
            }
          }
          if (num == 0) this.getRData()
          else if (num == 1) {
            for (let i = 0; i < this.ptermsLogistics.arr1.length; i++) {
              if (
                this.ptermsLogistics.arr1[i].pthird == this.orderlog.pthird &&
                this.ptermsLogistics.arr1[i].pterms == this.orderlog.pterms
              ) {
                this.ptermsKey = this.ptermsLogistics.arr1[i].spno
                break
              }
            }
            this.getPtermsData()
          }
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
        loading.close()
      })
    },
    // 選擇運送方式選項後觸發
    logisticsRadioHandler(item) {
      console.log(item)
      this.orderlog.lthird = item.lthird
      this.orderlog.logistics = item.logistics
      this.orderlog.temp = item.temp
      this.orderlog.spec = item.spec
      this.orderlog.ltime = item.ltime
      this.orderlog.store_name = ''
      this.orderlog.store_id = ''
      this.orderlog.slno = item.slno
      if (item.pterms == 6) {
        this.orderlog.pterms = item.pterms
      } else if (this.orderlog.pterms == 6) {
        this.orderlog.pterms = 0
      }
    },
    getStorePopup() {
      const lthird = this.orderlog.lthird
      const logistics = this.orderlog.logistics
      let routeData = this.$router.resolve({
        name: 'thirdlogistics',
        query: {
          lthird: lthird, // 送貨方式 1 綠界 2 ezship
          logistics: lthird === 1 ? logistics : 0, // 送貨方式 ezship 只需傳0
        },
      })
      window.open(routeData.href, '_blank')
    },
    // 取得超商資料
    getStoreData(e) {
      if (e.data.convenienceData) {
        console.log(e.data.convenienceData.payload)
        let convenienceData = e.data.convenienceData.payload
        this.orderlog.store_name = convenienceData.sname
        this.orderlog.store_id = convenienceData.sid
        if (this.orderlog.logistics == 0) this.orderlog.logistics = convenienceData.logistics
      }
    },
    getRData() {
      let rmp = this.order.rmp
      if (rmp) {
        for (let i = 5; i > 1; i--) {
          // 迴圈四次 i =  5,4,3,2
          let confirm = false
          let code = rmp.substr(0, i) // 取得+區碼四位 例如 +1876 牙買加
          this.countries.forEach((country) => {
            if (country.code === code) {
              confirm = true
              this.rmp_code = `${country.code},${country.en}` // 取得配對到的國家
              rmp = rmp.replace(code, '') // 去除區碼
            }
          })
          if (confirm) {
            break
          }
        }
      }
      this.orderlog.lthird = this.order.lthird
      this.orderlog.logistics = this.order.logistics
      this.orderlog.runame = this.order.runame
      this.orderlog.rmp = this.removeMpZero(rmp)
      this.orderlog.remail = this.order.remail
      this.orderlog.rshit = this.order.rshit
      this.orderlog.rcity = this.order.rcity
      this.orderlog.rarea = this.order.rarea
      this.orderlog.rzipcode = this.order.rzipcode
      this.orderlog.raddress1 = this.order.raddress1
      this.orderlog.raddress2 = this.order.raddress2
      this.orderlog.store_name = this.order.store_name
      this.orderlog.store_id = this.order.store_id
      this.orderlog.land = this.order.land
      this.orderlog.temp = this.order.temp
      this.orderlog.spec = this.order.spec
      this.orderlog.ltime = this.order.ltime
      this.rdata_Dialog = true
    },
    //
    saveRData(invalid) {
      if (invalid) return
      const loading = this.$vs.loading({
        target: this.$refs.saveRDataBtn,
        background: 'success',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      let chkpterms = 0
      if (this.order.pterms != 6 && this.orderlog.pterms !== this.order.pterms) {
        chkpterms = 1
      } else if (this.order.pterms == 6 && this.orderlog.pterms !== this.order.pterms) {
        chkpterms = 2
      }
      this.$axios({
        url: 'front/store/morder/uMOrderRData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          smono: this.$route.params.id,
          chkpterms: chkpterms,
          pterms: this.orderlog.pterms,
          lthird: this.orderlog.lthird,
          logistics: this.orderlog.logistics,
          runame: this.orderlog.runame,
          rmp: this.rmp_code.split(',')[0] + this.orderlog.rmp,
          remail: this.orderlog.remail,
          rshit: this.orderlog.rshit,
          rcity: this.orderlog.rcity,
          rarea: this.orderlog.rarea,
          rzipcode: this.orderlog.rzipcode,
          raddress1: this.orderlog.raddress1,
          raddress2: this.orderlog.raddress2,
          store_name: this.orderlog.store_name,
          store_id: this.orderlog.store_id,
          land: this.orderlog.land,
          temp: this.orderlog.temp,
          spec: this.orderlog.spec,
          ltime: this.orderlog.ltime,
          slno: this.orderlog.slno,
        },
      }).then((res) => {
        if (res.data.Success) {
          // 關閉按鈕loading
          loading.close()
          this.rdata_Dialog = false
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.loadOrder()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    // 選擇付款方式選項後觸發
    ptermsRadioHandler(item) {
      this.orderlog.pthird = item.pthird
      this.orderlog.pterms = item.pterms
    },
    getPtermsData() {
      this.pterms_Dialog = true
    },
    savePtermsData(invalid) {
      if (invalid) return
      const loading = this.$vs.loading({
        target: this.$refs.savePtermsDataBtn,
        background: 'success',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      this.$axios({
        url: 'front/store/morder/uMOrderPterms.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          smono: this.$route.params.id,
          pthird: this.orderlog.pthird,
          pterms: this.orderlog.pterms,
        },
      }).then((res) => {
        if (res.data.Success) {
          // 關閉按鈕loading
          loading.close()
          this.pterms_Dialog = false
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.loadOrder()
          this.setPtermsThirdApi()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    //發票資訊
    getInvoice() {
      this.orderlog.intype1 = this.order.intype1
      this.orderlog.intype2 = this.order.intype2
      this.orderlog.cname = this.order.cname
      this.orderlog.cvat = this.order.cvat
      this.orderlog.invoice = this.order.invoice
      this.invoice_Dialog = true
    },
    saveInvoice(invalid) {
      if (invalid) return
      const loading = this.$vs.loading({
        target: this.$refs.saveInvoiceBtn,
        background: 'success',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      this.$axios({
        url: 'front/store/morder/uMOrderInvoice.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          smono: this.$route.params.id,
          intype1: this.orderlog.intype1,
          intype2: this.orderlog.intype2,
          cname: this.orderlog.cname,
          cvat: this.orderlog.cvat,
          invoice: this.orderlog.invoice,
        },
      }).then((res) => {
        if (res.data.Success) {
          // 關閉按鈕loading
          loading.close()
          this.invoice_Dialog = false
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.loadOrder()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    //訂單備註
    getONote() {
      this.orderlog.onote = this.order.onote
      this.onote_Dialog = true
    },
    saveONote(invalid) {
      if (invalid) return
      const loading = this.$vs.loading({
        target: this.$refs.saveONoteBtn,
        background: 'success',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      this.$axios({
        url: 'front/store/morder/uMOrderONote.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          smono: this.$route.params.id,
          onote: this.orderlog.onote,
        },
      }).then((res) => {
        if (res.data.Success) {
          // 關閉按鈕loading
          loading.close()
          this.onote_Dialog = false
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.loadOrder()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    //商家備註
    getSNote() {
      this.orderlog.snote = this.order.snote
      this.snote_Dialog = true
    },
    saveSNote(invalid) {
      if (invalid) return
      const loading = this.$vs.loading({
        target: this.$refs.saveSNoteBtn,
        background: 'success',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      this.$axios({
        url: 'front/store/morder/uMOrderSNote.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          smono: this.$route.params.id,
          snote: this.orderlog.snote,
        },
      }).then((res) => {
        loading.close()
        if (res.data.Success) {
          this.snote_Dialog = false
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.loadOrder()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    //變更已取貨
    pickupMOrder() {
      const loading = this.$vs.loading({
        target: this.$refs.pickupMOrderBtn,
        background: 'success',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/store/morder/uMOrderPickup.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          smono: this.$route.params.id,
        },
      }).then((res) => {
        loading.close()
        if (res.data.Success) {
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.pickup_Dialog = false
          this.loadOrder()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    savegetMOrderPayed(invalid) {
      if (invalid) return
      const loading = this.$vs.loading({
        target: this.$refs.savegetMOrderPayedBtn,
        background: 'success',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      this.$axios({
        url: 'front/store/morder/uMOrderPayed.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          smono: this.$route.params.id,
        },
      }).then((res) => {
        loading.close()
        if (res.data.Success) {
          this.payed_Dialog = false
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.loadOrder()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    //訂單退款
    getRefundMOrder() {
      this.refundlog.rrefund = this.order.opays
      this.refundlog.repoint1 = 0
      this.refundlog.note = ''
      this.refund_Dialog = true
    },
    saveRefundMOrder(invalid) {
      if (invalid) return
      const loading = this.$vs.loading({
        target: this.$refs.saveRefundMOrderBtn,
        background: 'success',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      this.$axios({
        url: 'front/store/morder/uMOrderRefund.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          smono: this.$route.params.id,
          rrefund: this.refundlog.rrefund,
          repoint1: this.refundlog.repoint1,
          rnote: this.refundlog.rnote,
        },
      }).then((res) => {
        if (res.data.Success) {
          // 關閉按鈕loading
          loading.close()
          this.refund_Dialog = false
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.loadOrder()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    //取消訂單
    getCancelMOrder() {
      this.cancel_note = ''
      this.cancel_Dialog = true
    },
    saveCancelMOrder(invalid) {
      if (invalid) return
      const loading = this.$vs.loading({
        target: this.$refs.saveCancelMOrderBtn,
        background: 'success',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      this.$axios({
        url: 'front/store/morder/cancelMOrder.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          smono: this.$route.params.id,
          note: this.cancel_note,
        },
      }).then((res) => {
        if (res.data.Success) {
          // 關閉按鈕loading
          loading.close()
          this.cancel_Dialog = false
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.loadOrder()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    //刪除訂單
    // dMOrder() {
    //   this.$axios({
    //     url: 'front/store/morder/dMOrder.php',
    //     headers: {
    //       stoken: this.$store.state.login.userInfo.stoken,
    //     },
    //     kcval: true,
    //     data: {
    //       smono: this.$route.params.id,
    //     },
    //   }).then((res) => {
    //     if (res.data.Success) {
    //       // 關閉按鈕loading
    //       this.$myVS.openNoti(res.data.Message, '', undefined)
    //       this.$router.replace({
    //         name: 'orders',
    //       })
    //     } else {
    //       this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
    //     }
    //   })
    // },
    setPtermsThirdApi() {
      const kcval = this.$aes.encrypt(
        this.$store.state.login.userInfo.enval1,
        this.$store.state.login.userInfo.enval2,
        this.$store.state.login.userInfo.stoken
      )

      let link = `${process.env.VUE_APP_BASE_URL}/front/store/morder/setPtermsThirdTurn1.php`
      link += `?smono=${this.$route.params.id}`
      link += `&sn=${process.env.VUE_APP_SN}`
      link += `&stoken=${this.$store.state.login.userInfo.stoken}`
      link += `&kcval=${kcval}`

      window.open(link, '_blank')
    },
    setLogisticsThirdApi() {
      const kcval = this.$aes.encrypt(
        this.$store.state.login.userInfo.enval1,
        this.$store.state.login.userInfo.enval2,
        this.$store.state.login.userInfo.stoken
      )

      let link = `${process.env.VUE_APP_BASE_URL}/front/store/morder/setLogisticsThirdApi.php`
      link += `?smono=${this.$route.params.id}`
      link += `&sn=${process.env.VUE_APP_SN}`
      link += `&stoken=${this.$store.state.login.userInfo.stoken}`
      link += `&kcval=${kcval}`
      link += `&logistics_store=${this.orderShippingStatuslog.logistics_store}`
      link += `&logistics_num=${this.orderShippingStatuslog.logistics_num}`
      link += `&logistics_link=${this.orderShippingStatuslog.logistics_link}`

      //  logistics_store String(50) 物流商
      //  logistics_num String(50) 物流編號
      //  logistics_link String 物流連結

      window.open(link, '_blank')
    },
    // 選擇縣市時，改變鄉鎮市區和郵遞區號
    cityHandler(e) {
      const city = e
      let cityObj = this.TW_districts.find((item) => item.name === city)
      this.orderlog.rarea = cityObj.districts[0].name
      this.orderlog.rzipcode = cityObj.districts[0].zip
    },
    // 選擇鄉鎮市區時，改變郵遞區號
    districtHandler(e) {
      const city = this.orderlog.rcity
      let obj = this.TW_districts.find((item) => item.name === city).districts.find((district) => district.name === e)
      this.orderlog.rzipcode = obj.zip
    },
    // 開啟揀貨單
    toPackingSlip() {
      let routeData = this.$router.resolve({ name: 'packing_slip', query: { order: 'LS000000001' } })
      window.open(routeData.href, '_blank')
    },
  },
  beforeDestroy() {
    window.removeEventListener('message', this.getStoreData)
    document.removeEventListener('click', this.mouseClick)
  },
}
</script>

<style lang="scss" scoped>
.box {
  @apply bg-white rounded-xl p-4 space-y-4;
}

.title {
  @apply text-sm  text-gray-text_dark mb-1;
}

.product_list_1 {
  flex-grow: 1;
  min-width: 300px;

  // 編輯出貨資訊 dialog
  &.in_shipping_content {
    min-width: unset;
  }
}
.product_list_2 {
  width: 100px;

  // 編輯出貨資訊 dialog
  &.in_shipping_content {
    width: 110px;
  }
}
.product_list_3 {
  width: 100px;
  text-align: right;
}

.order_icon_btn {
  @apply flex items-center space-x-2 text-gray-text_light;
}
</style>
